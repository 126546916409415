import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyD_n25Gwa8IaiYylmd7v5k-e3EXt3q0Ou8",
  authDomain: "ethraa-c9ebd.firebaseapp.com",
  projectId: "ethraa-c9ebd",
  storageBucket: "ethraa-c9ebd.appspot.com",
  messagingSenderId: "236949231061",
  appId: "1:236949231061:web:95686c0283d959e598595c",
  measurementId: "G-B8BY7FVFNZ"
};

initializeApp(firebaseConfig);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <App />
);
