import React from 'react';
import { getDatabase, ref, push, set } from 'firebase/database';
import "./EmailList.css";

export default function EmailList({ isEnglish }) {
  const database = getDatabase();

    const handleSubmit = async (e) => {
        e.preventDefault();
        const email = e.target.elements.email.value;
        if (email) {
						const dataRef = ref(database, 'EmailList');
            try {
                await set(push(dataRef), {
                    email: email
                });
                alert(`${isEnglish? "Email added successfully" : "تم إضافة الإيميل بنجاح" }`);
            } catch (error) {
                console.error("Error adding email: ", error);
            }
        }
        e.target.elements.email.value = '';
    };

    return (
			<div className='EmailListBg'>
			<form className='EmailListContainer' onSubmit={handleSubmit}>
				<div>{isEnglish?"Be the first to know about our latest offers!":"كن أول من يعرف بأحدث العروض والأخبار" + "!"}</div>
				<input required type="email" name="email" placeholder={isEnglish?'Enter your email here':'أدخل بريدك هنا'} />
				<button className='EmailLisBtn' type='submit'>{isEnglish?"Sign Me Up":"أريد الانضمام"}</button>
				<div className='FooterCopyWrite'>{isEnglish?"© 2024 ethraa | All Rights Reserved":"© 2024 إثراء | جميع الحقوق محفوظة"}</div>
			</form>
		</div>
    );
}
