import React from 'react'
import "./Header.css"
import LanguageToggle from './LanguageToggle'
import ActionButton from './ActionButton'

export default function Header({ isEnglish, setIsEnglish }) {
	const handleClick = () => {
		window.location.href = "https://www.instagram.com/ethraa_ar";
	};
	return (
		<div className='LandingHeader'>
			<div className='LandingHeaderContainer'>

				<div className="LandingLogo"></div>

				<div className='LandingHeaderActions'>
					<LanguageToggle isEnglish={isEnglish} setIsEnglish={setIsEnglish} />
					<ActionButton handleClick={handleClick} Text={isEnglish ? "Follow Us" : "تابع حسابنا"} />
				</div>

			</div>
		</div>
	)
}
