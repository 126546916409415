import React, { useState, useEffect } from 'react';
import "./Countdown.css";

export default function Countdown({isEnglish}) {
    const calculateTimeLeft = () => {
        const difference = +new Date("2024-05-01") - +new Date();
        let timeLeft = {};

        if (difference > 0) {
            timeLeft = {
                days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                minutes: Math.floor((difference / 1000 / 60) % 60),
                seconds: Math.floor((difference / 1000) % 60)
            };
        }

        return timeLeft;
    };

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
        const timer = setTimeout(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);

        return () => clearTimeout(timer);
    });

    return (
        <div className='CountdownContainer En'>
            <div className='CountdownTitle'>{isEnglish?"🚀 Launch Countdown":"🚀 العد التنازلي للإطلاق"}</div>
            <div className='CountdownSectionsContainer'>
                <div className='CountdownSection'>
                    <div className='CountdownNumber'>{timeLeft.days}</div>
                    <div>{isEnglish?"Days":"أيام"}</div>
                </div>
                <div className='CounterDivider'>:</div>
                <div className='CountdownSection'>
                    <div className='CountdownNumber'>{timeLeft.hours}</div>
                    <div>{isEnglish?"Hours":"ساعات"}</div>
                </div>
                <div className='CounterDivider'>:</div>
                <div className='CountdownSection'>
                    <div className='CountdownNumber'>{timeLeft.minutes}</div>
                    <div>{isEnglish?"Minutes":"دقائق"}</div>
                </div>
                <div className='CounterDivider'>:</div>
                <div className='CountdownSection'>
                    <div className='CountdownNumber'>{timeLeft.seconds}</div>
                    <div>{isEnglish?"Seconds":"ثواني"}</div>
                </div>
            </div>
        </div>
    );
}
