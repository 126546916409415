import React, { useState, useEffect } from 'react';
import './HomePage.css';
import Header from './Components/Header';
import Countdown from './Components/Countdown';
import EmailList from './Components/EmailList';

export default function HomePage() {
    const storedLanguage = localStorage.getItem("isEnglish");
    const [isEnglish, setIsEnglish] = useState(storedLanguage === 'true'); // Check if storedLanguage is 'true'

    useEffect(() => {
        localStorage.setItem("isEnglish", isEnglish);
    }, [isEnglish]);

    const handleClick = () => {
        window.open("https://www.instagram.com/ethraa_ar", "_blank");
    };

    return (
        <div className={isEnglish ? 'En' : ''}>
            <Header isEnglish={isEnglish} setIsEnglish={setIsEnglish} />
            <div className='LandingPageContent'>
                <div className='ProfilesImage'></div>
                <div className='LandingSlogan'>
                    {isEnglish ?
                        <span>Grow your income by<br />building a <span className='Blue'>community</span></span>
                        :
                        <span>طوّر دخلك عن طريق بناء<br /><span className='Blue'>مجتمع خاص بك</span></span>
                    }
                </div>
                <div className='LandingDescription'>
                    {isEnglish ?
                        <span>The only tool you need to turn followers into subscribers; <span className='Blue'>Get paid for your passion</span></span>
                        :
                        <span>الأداة الوحيدة التي تحتاجها لتحويل جمهورك من متابعين إلى مشتركين؛ <span className='Blue'>حوّل شغفك إلى مصدر دخل</span></span>
                    }
                </div>
                <div className="LandingPrimaryBtn" onClick={handleClick}>
                    <div className='LandingPrimaryBtnContainer'>
                        <div className='InstagramIcon'></div>
                        <div>{isEnglish ? "Follow Us" : "تابع حسابنا"}</div>
                    </div>
                </div>
                <div className={isEnglish ? "LandingAppUiEn" : "LandingAppUiAr"}></div>
            </div>
            <Countdown isEnglish={isEnglish} />
            <EmailList isEnglish={isEnglish} />
        </div>
    );
}
