import React from 'react'
import "./LanguageToggle.css"

export default function LanguageToggle({isEnglish, setIsEnglish}) {

	return (
		<div className='ToggleContainer'
			onClick={()=>{
				localStorage.setItem("isEnglish", !isEnglish);
				setIsEnglish(!isEnglish);
			}}
		>
			<div className={isEnglish?'Toggle ActiveToggle':'Toggle'}>En</div>
			<div className={!isEnglish?'Toggle ActiveToggle':'Toggle'}>ع</div>
		</div>
	)
}
